import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { sprintf } from 'sprintf-js';

import { logIn } from 'api/userEnvironment/apiUserEnvironment';
import type { ApiUser } from 'api/userEnvironment/types/ApiUserEnvironment';
import type { FormUsageContext } from 'components/Form/Form';
import type { FormSubmitFunction } from 'components/Form/FormContext';
import type { LoginFormValues } from 'components/LoginForm/LoginForm';
import { LoginForm } from 'components/LoginForm/LoginForm';
import { useUserEnvironmentActions } from 'contexts/UserEnvironmentContext';
import { useIsMounted } from 'hooks/useIsMounted';
import { authSignupRoute } from 'modules/auth/routing/auth.routes';
import { cmsApiSubscribeToSubsite } from 'modules/cms/api/cmsApiSubscribeToSubsite';
import type { CmsApiSubscribe } from 'modules/cms/api/types/CmsApiSubscribe';
import { useOrgSavedToast } from 'modules/listing/hooks/useOrgSavedToast';
import type { ApiSavedSearchUnsaved } from 'modules/userDashboard/api/savedSearch/types/UserDashboardApiSavedSearch';
import { userDashboardSaveListing } from 'modules/userDashboard/zustand-stores/userDashboardSavedListingsStore';
import { userDashboardSaveSearch } from 'modules/userDashboard/zustand-stores/userDashboardSavedSearchesStore';
import { trackLogin } from 'utils/analytics/track/trackLogin';
import { configureTrackJs } from 'utils/configureTrackJs';
import { LOGIN_PROVIDER_NAMES } from 'utils/constants/general/loginProviderNames';
import { createCookie, deleteCookie } from 'utils/cookies';
import { getThirdPartyLoginUrl } from 'utils/getThirdPartyLoginUrl';
import {
  generateRedirectUrl,
  saveActionsToTmpStorage,
} from 'utils/temporaryStorage';
import { getRelativeURL } from 'utils/url/getRelativeURL';
import { hasParam } from 'utils/url/hasParam';
import { isExternalURL } from 'utils/url/isExternalURL';
import {
  clearFlashMessages,
  pushFlashMessage,
} from 'zustand-stores/flashMessagesStore';
import { hideModal, showModal } from 'zustand-stores/modalStore';

type Props = {
  analyticsTitle: string;
  usageContext: FormUsageContext;
  redirectTo?: string | null;
  // @TODO - we only want `redirectTo` or `redirectUrl`
  // Currently, `redirectUrl` refers to the `redirectUrl`
  // that comes from the CmsSignupBlock
  redirectUrl?: string | null;
  email?: string | null;
  savedListing?: { id: string; type: string; name: string };
  savedSearch?: ApiSavedSearchUnsaved;
  cmsSubscribe?: CmsApiSubscribe;
  subscribedSubsite?: string | null;
  inviteId?: string | null;
  onLogin?: (user: ApiUser) => void;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signUpOverrideFn?: (...args: Array<any>) => any;
  centered?: boolean;
  initialWarning?: string | null;
  compactView?: boolean;
  uncloseable?: boolean;
};

export function LoginFormContainer(props: Props) {
  const { setUserEnvironment } = useUserEnvironmentActions();

  const {
    initialWarning: initialWarningFromProps,
    email,
    redirectTo,
    cmsSubscribe,
    uncloseable,
    redirectUrl,
    savedListing,
    savedSearch,
    subscribedSubsite,
    analyticsTitle,
    usageContext,
    centered,
    compactView,
    inviteId,
    signUpOverrideFn,
    onLogin,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const isMounted = useIsMounted();

  const [thirdPartyLoginError, setThirdPartyLoginError] = useState<
    string | null | undefined
  >(null);
  const [initialWarning, setInitialWarning] = useState<
    string | null | undefined
  >(initialWarningFromProps);

  const initialValues = {
    email: email || '',
    password: '',
  };

  useEffect(() => {
    const { error, provider } = queryString.parse(location.search);
    if (!error) return;

    // @ts-expect-error TS(2538): Type '(string | null)[]' cannot be used as an inde... Remove this comment to see the full error message
    const providerName = LOGIN_PROVIDER_NAMES[provider as string];
    let errorMessage;

    if (error === 'EXISTING_ACCOUNT' && providerName) {
      // TRANSLATORS: error message when user tries to log in using
      // TRANSLATORS: Facebook or Google but they already had an account
      // TRANSLATORS: that did not use that login provider
      errorMessage = sprintf(
        getText(
          'Please log in first to associate that %(provider)s ' +
            'account to your existing Idealist account',
        ),
        {
          provider: providerName,
        },
      );
    }

    if (error === 'MISMATCHED_ACCOUNTS' && providerName) {
      // TRANSLATORS: User tried to link Facebook/Google account, but
      // TRANSLATORS: it was already used by another Idealist acccount
      errorMessage = sprintf(
        getText(
          'That %(provider)s account is already associated with ' +
            'another Idealist account',
        ),
        {
          provider: providerName,
        },
      );
    }

    if (error === 'FAILED_OAUTH_LOGIN') {
      // TRANSLATORS: user used 3rd party authentication like Google, but it failed
      errorMessage = getText(
        'Authentication did not work as expected, please try again.',
      );
    }

    if (error === 'USER_FROZEN') {
      // TRANSLATORS: user tried to login, but user is frozen
      errorMessage = getText(
        'Your account has been frozen for violating our Terms of Service.',
      );
    }

    if (errorMessage) {
      // remove error and provider params from URL
      const params = queryString.parse(location.search);
      delete params.error;
      delete params.provider;
      navigate(
        {
          pathname: location.pathname,
          search: queryString.stringify(params),
        },
        { replace: true },
      );
      setThirdPartyLoginError(errorMessage);
    }
  }, [location.search, location.pathname, navigate]);

  const showSignupModal = useCallback(
    (currentEmail: string) => {
      showModal('SIGNUP', {
        email: currentEmail,
        redirectTo,
        savedListing,
        savedSearch,
        cmsSubscribe,
        subscribedSubsite,
        uncloseable,
      });
    },
    [
      redirectTo,
      savedListing,
      savedSearch,
      cmsSubscribe,
      subscribedSubsite,
      uncloseable,
    ],
  );

  const getSignupUrl = useCallback(
    (currentEmail: string) => ({
      pathname: authSignupRoute.with({ invite: inviteId?.toString() }),
      state: {
        email: currentEmail,
        redirectTo,
        savedListing,
        savedSearch,
        cmsSubscribe,
        subscribedSubsite,
      },
    }),
    [
      inviteId,
      redirectTo,
      savedListing,
      savedSearch,
      cmsSubscribe,
      subscribedSubsite,
    ],
  );

  const showForgotPasswordModal = useCallback((state: { email: string }) => {
    showModal('FORGOT_PASSWORD', state);
  }, []);

  const getForgotPasswordUrl = useCallback(
    (state: { email: string }) => ({
      pathname: '/forgot-password',
      state,
    }),
    [],
  );

  const getThirdPartyLoginUrlCallback = useCallback(
    (provider: 'facebook' | 'google' | 'apple') =>
      getThirdPartyLoginUrl({
        provider,
        redirectTo,
        inviteId,
        savedListing,
        savedSearch,
        cmsSubscribe,
        subscribedSubsite,
      }),
    [
      redirectTo,
      inviteId,
      savedListing,
      savedSearch,
      cmsSubscribe,
      subscribedSubsite,
    ],
  );

  const { showOrgSavedToast } = useOrgSavedToast();

  const submitLogin: FormSubmitFunction<LoginFormValues> = useCallback(
    async ({ values, handleError }) => {
      deleteCookie('userDismissedNotificationPreferencesDialog');
      setInitialWarning(null);
      const loginInfo = { ...values };

      if (subscribedSubsite) {
        loginInfo.subscribeSubsiteId = subscribedSubsite;
      }

      let user: ApiUser | null = null;
      let response;
      try {
        response = await logIn(values);
      } catch (error) {
        handleError(error as Error);
        return;
      } finally {
        if (isMounted()) {
          setThirdPartyLoginError(null);
        }
      }
      if (response.result === 'LOGIN_SUCCESS') {
        setUserEnvironment(response);
        ({ user } = response);
        trackLogin(user, 'Email Password');
        configureTrackJs(user.id);

        if (user.locale) {
          createCookie('_LOCALE_', user.locale, 1825);
        }

        hideModal();
        clearFlashMessages();

        const isSearchPage =
          hasParam(location.search, 'q') || hasParam(location.search, 'pq');

        const hasTemporaryStorage = Boolean(redirectUrl?.includes('tmpId'));

        if (redirectUrl) {
          if (isExternalURL(redirectUrl)) {
            window.location.href = redirectUrl;
          } else {
            navigate(getRelativeURL(redirectUrl), {
              replace: isSearchPage || hasTemporaryStorage,
            });
          }
        }
        if (savedListing) {
          const { type, id } = savedListing;
          await userDashboardSaveListing(id, type);
          if (type === 'ORG') showOrgSavedToast();
        }

        if (savedSearch) {
          await userDashboardSaveSearch(savedSearch);
        }

        if (subscribedSubsite) {
          await cmsApiSubscribeToSubsite(subscribedSubsite);
          pushFlashMessage({ type: 'SUBSCRIBED_SUBSITE' });
          showModal('SUBSCRIBED_TO_SUBSITE', {});
        }
        if (onLogin) {
          onLogin(user);
        }
      } else {
        response.result satisfies 'MFA_REQUIRED';
        hideModal();
        const to = redirectTo ?? window.location.href;
        const tmpId = await saveActionsToTmpStorage({
          cmsSubscribe,
          savedListing,
          savedSearch,
          subscribedSubsite,
        });

        const toWithTmpId =
          tmpId !== null ? generateRedirectUrl(to, tmpId) : to;

        navigate(
          {
            pathname: `/mfa/${response.mfaToken.id}`,
            search: queryString.stringify({
              to: toWithTmpId,
            }),
          },
          { replace: true },
        );
      }
    },
    [
      subscribedSubsite,
      isMounted,
      setUserEnvironment,
      location.search,
      redirectUrl,
      savedListing,
      savedSearch,
      onLogin,
      navigate,
      showOrgSavedToast,
      redirectTo,
      cmsSubscribe,
    ],
  );

  return (
    <LoginForm
      analyticsTitle={analyticsTitle}
      usageContext={usageContext}
      initialValues={initialValues}
      thirdPartyLoginError={thirdPartyLoginError}
      showSignupModal={showSignupModal}
      getSignupUrl={getSignupUrl}
      signUpOverrideFn={signUpOverrideFn}
      showForgotPasswordModal={showForgotPasswordModal}
      getForgotPasswordUrl={getForgotPasswordUrl}
      getThirdPartyLoginUrl={getThirdPartyLoginUrlCallback}
      submitLogin={submitLogin}
      centered={centered}
      initialWarning={initialWarning}
      compactView={compactView}
    />
  );
}
