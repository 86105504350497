import type { NonNullableFields } from 'types/NonNullableFields';
import { postJson } from 'utils/http/postJson';

import type { ApiUserEnvironment } from './types/ApiUserEnvironment';

export async function signUp(
  userInfo: Record<string, unknown>,
): Promise<NonNullableFields<ApiUserEnvironment>> {
  return postJson('/data/website/signup', userInfo);
}

type LoginPayload = {
  email: string;
  password: string;
};

type LoginSuccessResponse = {
  result: 'LOGIN_SUCCESS';
} & NonNullableFields<ApiUserEnvironment>;

type MfaRequiredResponse = {
  result: 'MFA_REQUIRED';
  mfaToken: {
    id: string;
  };
};

type LoginResponse = LoginSuccessResponse | MfaRequiredResponse;

export async function logIn(loginInfo: LoginPayload): Promise<LoginResponse> {
  return postJson('/data/website/login', loginInfo);
}

export function forgotPassword(email: string) {
  return postJson('/data/website/forgot-password', {
    email,
  });
}

export async function resetPassword({
  password,
  token,
}: {
  password: string;
  token: string;
}): Promise<NonNullableFields<ApiUserEnvironment>> {
  return postJson(`/data/website/reset-password/${token}`, {
    password,
  });
}

type MfaCodePayload = {
  code: string;
  tokenId: string;
};

export async function submitMfaCode(
  payload: MfaCodePayload,
): Promise<NonNullableFields<ApiUserEnvironment>> {
  return postJson('/data/website/mfa', payload);
}
